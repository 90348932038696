<script>
  import { get } from 'vuex-pathify'

  export default {
    computed: {
      ...get('config', ['marketplaceEnabled', 'isCurrentBoxWaitlist']),
    },
  }
</script>

<template>
  <div class="max-w-74 2xs:max-w-85 xs:max-w-95 sm:max-w-120 lg:max-w-175 mx-auto">
    <div class="text-xs xs:text-md text-center mb-6" style="line-height: 1.2">
      <p v-if="marketplaceEnabled" class="max-w-60 xs:max-w-80 md:max-w-125 mx-auto">
        Shop 800+ ethical and sustainable products at up to <strong>70%&nbsp;off</strong> in our
        members-only Add-On Market.
      </p>
      <p v-else class="sm:px-7 lg:px-0">
        Welcome! Here’s your one-time access to our New Members Market, a special curation of our
        seasonal Add-On Market. Save up to 70%+ with exclusive product discounts. Discover more
        ethically and sustainably made products you'll love!
      </p>
    </div>
    <BaseButton
      color="black"
      class="max-w-115 mx-auto"
      @click="$navigate({ name: marketplaceEnabled ? 'Market' : 'SubscribeMarket' })"
    >
      <span class="font-semibold text-lg">Continue</span>
    </BaseButton>
  </div>
</template>
