<script>
  import { get } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import SubscribeWelcomeCta from '@/components/subscribe/SubscribeWelcomeCta'
  import screen from '@/helpers/screen'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if (
        ((await store.dispatch('account/loggedInAsync')) === false ||
          !store.get('subscribe/subscriptionCreated')) &&
        !store.get('account/isAdmin')
      ) {
        return redirect({ name: 'SubscribePlan' })
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      SubscribeWelcomeCta,
    },
    mixins: [guardMixin],
    data() {
      return {
        products: [
          {
            imageUrl: '/static/subscribe/NMM-01.png',
            percentOff: 71,
            alt: 'hand cream',
          },
          {
            imageUrl: '/static/subscribe/NMM-02.png',
            percentOff: 40,
            alt: 'workout anklet',
          },
          {
            imageUrl: '/static/subscribe/NMM-03.png',
            percentOff: 42,
            alt: 'bag',
          },
          {
            imageUrl: '/static/subscribe/NMM-04.png',
            percentOff: 66,
            alt: 'product bundle',
          },
        ],
      }
    },
    computed: {
      ...get('account', ['loggedIn']),
      ...get('feature', ['flags']),
      ...get('subscribe', ['subscriptionCreated']),
      screen,
      heroImage() {
        if (this.screen.md && !this.screen.lg) {
          return '/static/subscribe/Hero-sm.jpg'
        }
        if (this.screen.xl) {
          return '/static/subscribe/Hero-lg.jpg'
        }
        // find largest screen size that evaluates to true to get breakpoint
        const screen = Object.keys(this.screen)
          .reverse() // reverse order to get largest -> smallest
          .find((screen) => this.screen[screen]) // return first screen that evaluates to true
        return `/static/subscribe/Hero-${screen}.jpg`
      },
      newMemberMarketIsEnabled() {
        return this.flags['new-member-market'] ?? false
      },
    },
    created() {
      if (!this.newMemberMarketIsEnabled) {
        this.$navigate({ name: 'Home' })
      }
    },
  }
</script>

<template>
  <section v-if="newMemberMarketIsEnabled" class="pb-15 sm:pb-25 bg-dawn">
    <div class="text-center pb-66px 2xs:pb-50px xs:pb-93px lg:pb-73px">
      <!-- Hero image -->
      <img :src="heroImage" alt="" class="absolute left-0 h-full w-full object-cover" />
      <!-- Hero copy -->
      <h1
        class="font-semibold text-xs xs:text-md sm:text-xl lg:text-25px pt-50px xs:pt-93px sm:pt-87px lg:pt-54px"
        style="line-height: 1.2"
      >
        YOU’VE EARNED EXCLUSIVE ACCESS TO OUR
      </h1>
      <h2
        :class="`mt-14px mb-15px font-heading font-semibold text-2xl 2xs:text-4xl xs:text-6xl lg:text-72px leading-none`"
      >
        New Members Market!
      </h2>
      <SubscribeWelcomeCta />
    </div>

    <!-- Preview products -->
    <div class="bg-primary py-11 sm:py-13 px-7 border-t-2 border-black">
      <div class="grid grid-cols-2 gap-y-11 sm:flex sm:justify-center">
        <div
          v-for="(product, productIndex) in products"
          :key="productIndex"
          :class="`flex justify-center ${productIndex !== 3 && screen.sm ? 'mr-2' : ''}`"
        >
          <div>
            <BaseImage
              :src="product.imageUrl"
              :alt="product.alt"
              responsive="xs"
              class="max-w-36 md:max-w-42 lg:max-w-58"
              style="border-radius: 25px"
            />
            <!-- Percent off badge -->
            <div class="absolute left-0 ml-2 top-0 -mt-7">
              <div
                class="flex justify-center items-center w-13 h-13 lg:w-19 lg:h-19 rounded-full bg-sunshine font-heading font-bold text-center lg:text-xxl leading-none"
              >
                <span class="overflow-hidden ellipsis">
                  <span class="ml-2px"> {{ product.percentOff }}%</span>
                  <span> OFF </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
